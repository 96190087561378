import api from '../index'

const order = {
    // 获取订单列表
    getOrderList(data) {
        return api({
            url: 'v2/ofs/order-list',
            method: 'post',
            data: data
        })
    },
    creatOrder(data) {
        return api({
            url: 'v2/ofs/order-create',
            method: 'post',
            data: data
        })
    },
    getOrderInfo(data) {
        return api({
            url: 'v2/ofs/order-info',
            method: 'post',
            data: data
        })
    },
    cancelOrder(data) {
        return api({
            url: 'v2/ofs/order-cancel',
            method: 'post',
            data: data
        })
    },
    signOrder(data) {
        return api({
            url: 'v2/ofs/order-sign',
            method: 'post',
            data: data
        })
    },
    wxCancelOrder(data) {
        return api({
            url: 'v2/wx/ofs-refundorder',
            method: 'post',
            data: data
        })
    },

}
export default order
