// 微信支付 js相关交互
import wx from 'weixin-js-sdk';
import { Toast } from 'vant'

const wexinPay = function(params) {
	return new Promise((resolve, reject) => {
    //验证微信环境
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: params.appId, // 必填，公众号的唯一标识
      timestamp: params.timeStamp, // 必填，生成签名的时间戳
      nonceStr: params.nonceStr, // 必填，生成签名的随机串
      signature: params.paySign, // 必填，签名，见附录1
      jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    wx.ready(function() {
      wx.chooseWXPay({
        timestamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
        package: params.packageVal, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: params.signType, // 签名方式，默认为\'SHA1\'，使用新版支付需传入\'MD5\'
        paySign: params.paySign, // 支付签名
        success: function(res) {
          //支付成功
          resolve(res)
        },
        fail: function(res) {
          //支付失败
          reject(res)
        },
        complete: function(res) {
          Toast.clear()
        },
        cancel: function(res) {
          reject(res)
        }
      });
    });
    wx.error(function(res) {
      // config信息验证失败会执行error函数
      Toast({type: 'fail', message: '支付失败，请扫码重试'});
      reject(res)
    });
  })

}

export default wexinPay;