import api from '../index'

const wx = {
    // 获取预制菜列表
    payOfsOrder(data) {
        return api({
            url: 'v2/wx/ofs-order',
            method: 'post',
            data: data
        })
    }

}
export default wx
